<template>
<b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"

    >
<div>
  

        <b-row>

        <b-card class="mb-1 col-md-12" :title="'Scan '+scan.name+' (#'+scan.id+')'">
          <b>Client name :</b> {{scan.user.name}}<br />
            <b>Scan date :</b> {{scan.date}}<br /><br />
          <scan-viewer v-if="scan" :scan="scan" />
          <!--<scan-canvas v-if="scan" :scan="scan" />-->
            <a target="_blank" :href="scan.measures_url" download="true">
              <b-button
                
                variant="dark"
              >
                Download measures
              </b-button>
            </a>
            <br /><br />
            <a target="_blank" :href="scan.point_cloud_url"  download="true">
            <b-button
             
              variant="dark"
            >
              Download FacePointCloud
            </b-button>
            </a>
             <br /><br />
            <a target="_blank" :href="scan.unprojected_point_cloud_url"  download="true">
            <b-button
             
              variant="dark"
            >
              Download Unprojected PointCloud
            </b-button>
            </a>
            <br /><br />
            <a target="_blank" :href="scan.measure_points_url"  download="true">
            <b-button
             
              variant="dark"
            >
              Download MeasurePoints
            </b-button>
            </a>
            
            <br /><br />
            
            <b-button v-if="selectedScan && selectedScan==scan.id"
              
              variant="success"
            >
              Selected for order
            </b-button>
            <b-button v-else @click="selectScan(scan.id)"
            
              variant="dark"
            >
              Use scan for order
            </b-button>
            &nbsp;
            <b-button @click="selectScan(scan.id)"
         
              variant="dark"
            >
              Delete Scan
            </b-button>
        </b-card>
   

  </b-row>

  <b-row>
      
        <b-card class="mb-1 col-md-4" v-if="!scan" :title="'Scan'">
          <b-card-text>
            <b>Client name :</b> <br />
            <b>Scan date :</b> <br /><br />

            <a target="_blank" download="true">
              <b-button
                
                variant="dark"
              >
                Download measures
              </b-button>
            </a>
            <br /><br />
            <a target="_blank"  download="true">
            <b-button
             
              variant="dark"
            >
              Download FacePointCloud
            </b-button>
            </a>
          </b-card-text>

          <br />
          <table class="table">
            
          </table>
          
           
            <b-button
            
              variant="dark"
            >
              Use scan for order
            </b-button>
            &nbsp;
            <b-button 
         
              variant="dark"
            >
              Delete Scan
            </b-button>
        </b-card>

        </b-row>
  
</div>
</b-overlay>
</template>

<script>
import {BModal, BButton,BOverlay, VBModal, BRow, BCard, BCardText, BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, } from 'bootstrap-vue'
import {APIRequest, Woocommerce} from '@simb2s/senseye-sdk'
import { VueGoodTable } from 'vue-good-table'
import ScanPopup from '../../components/ScanPopup.vue'
import PrescriptionPopup from '../../components/PrescriptionPopup.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import emitter from 'tiny-emitter/instance'
import ScanCanvas from './ScanCanvas.vue'
import ScanViewer from './ScanViewer.vue'

export default {
  components: {
    BCard,
    BCardText,
    VueGoodTable,
    BAvatar,
    BOverlay,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BModal,
    BButton,
    BRow,
    ScanPopup,
    PrescriptionPopup,
    ScanCanvas,
    ScanViewer
  },
  data() {
    return {
      scan:null,
      selectedScan:localStorage.senseye_b2b_selected_scan,
      loading:true,
      measurements:null
    }
  },
  
  methods:{
    async deleteScan(id)
    {
      
      this.loading=true
      await APIRequest.delete('scans',id)
      if(this.selectedScan) {
        if(this.selectedScan==id) {
          localStorage.senseye_b2b_selected_scan=null
        }
      } 
      this.$router.push({name:'scans'})
      },
    async getScan() {
      let scan=await APIRequest.detail('scans',this.$route.params.id)
      this.scan={}
      this.scan=scan

      let measurements=scan.measurements.replace('."',',"')
      measurements=measurements.replace('."',',"')
      measurements=measurements.replace('."',',"')
      measurements=measurements.replace('."',',"')
      
  
      this.measurements=JSON.parse(measurements)


      //console.log(measurements)
      //import data from "/Users/Documents/workspace/test.json"
    },
    selectScan(id) {
      this.selectedScan=this.scan.id
      localStorage.senseye_b2b_selected_scan=this.scan.id
   
    },
    
    
  },
  watch:{
    
  },
  async mounted()
  {
    this.loading=true
    await this.getScan()
    this.loading=false
  }
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
.overlay-scan {
  width:100%;
  height:100vh;
  z-index:999999;
}
</style>
