<template>
    <div ref='canvas' />
</template>

<script>
// import { Clock, PerspectiveCamera, Scene, WebGLRenderer } from 'three'
import * as THREE from 'three'
import TrackballControls from 'three-trackballcontrols'
import { ConvexGeometry } from '/node_modules/three/examples/jsm/geometries/ConvexGeometry.js';
import { OrbitControls } from '/node_modules/three/examples/jsm/controls/OrbitControls.js'

export default {
    name: 'ScanCanvas',
    props:['scan'],
    
    data: function() {
        let pointcloud=this.scan.coordinates.split('\n')
      
        console.log('point cloud')
        //console.log(pointcloud)

        const scene = new THREE.Scene()
        // const composer = new THREE.EffectComposer(new WebGLRenderer())
        // const effectPass = new THREE.EffectPass(camera, new BloomEffect())
       
        const renderer = new THREE.WebGLRenderer({ antialias: true })
       
        const geometry = new THREE.BoxGeometry(1, 1, 1)
        const material = new THREE.MeshStandardMaterial({
            side: THREE.FrontSide,
            color: 'hsl(0, 100%, 50%)',
            wireframe: false
        })
        const cube = new THREE.Mesh(geometry, material)
        const axes = new THREE.AxesHelper(5)

        // create a simple square shape. We duplicate the top left and bottom right
        // vertices because each vertex needs to appear once per triangle.

        const vertices = [];

        for ( let i = 0; i < pointcloud.length; i ++ ) {
            console.log(i)
            let values=pointcloud[i].split(',');
            const vertex = new THREE.Vector3(values[0]*100,values[1]*100,values[2]*100);
            vertices.push( vertex );

        }

        const pointsMaterial = new THREE.PointsMaterial( {

            color: 0x0080ff,
            size: 1,
            alphaTest: 0.5

        } );

        const pointsGeometry = new THREE.BufferGeometry().setFromPoints( vertices );
        // itemSize = 3 because there are 3 values (components) per vertex
        const points = new THREE.Points( pointsGeometry, pointsMaterial );

        
        const meshMaterial = new THREE.MeshPhongMaterial({
            color: 0x5e5e5e,    // red (can also use a CSS color string here)
            flatShading: false,
            emissive:0x5e5e5e,
            specular:0x5e5e5e,
            vertexColors:false,
            wireframe: true
            });

        const meshGeometry = new ConvexGeometry( vertices );

        console.log('mesh geo')
        console.log(meshGeometry)

        console.log('vertices')
        console.log(vertices)

        const mesh1 = new THREE.Mesh( meshGeometry, meshMaterial );
        const camera = new THREE.PerspectiveCamera( 45, window.innerWidth*0.7/window.innerHeight, 1, 1000 )
        const directionalLight = new THREE.DirectionalLight( 0xffffff, 0.5 );
        const sLight = new THREE.SpotLight(0xffffff, 1); // spotfény segédgeometriával
        

        return {
            scene: scene,
            camera: camera,
            controls: [],
            renderer: renderer,
            light: directionalLight,
            light2:sLight,
            cube: cube,
            axes: axes,
            speed: 0.01,
            mesh:points,
            mesh1:mesh1
        }
    },
    created: function() {
        const gridHelper = new THREE.GridHelper(100, 100)
        this.scene.add(gridHelper)
        this.scene.add(this.camera)
        this.scene.add(this.light)
        this.scene.add(this.light2)
        //this.scene.add(this.cube)
        //this.scene.add(this.mesh)
        this.scene.add(this.mesh1)
        this.scene.add(this.axes)

        
        this.mesh1.position.set(0,0,0);
        console.log('mesh ')
        console.log(this.mesh1)
   
        this.renderer.setSize(window.innerWidth*0.6, window.innerHeight)
       

        this.light.castShadow = true;
        this.light.shadow.mapSize.width = 2048;
        this.light.shadow.mapSize.height = 2048;

        this.light2.position.set(-50, -600, 200);
        this.light2.castShadow = true;
        this.light2.distance = 0;

        this.light2.angle = Math.PI * 0.2;
        this.light2.shadow.camera.near = 10;
        this.light2.shadow.camera.far = 300;
        this.light2.shadow.mapSize.width = 2048;
        this.light2.shadow.mapSize.height = 2048;

       
        
        this.scene.background = new THREE.Color( 0xb3b3b3);

        this.renderer.shadowMap.enabled = true;

  
        this.camera.position.set( 0, 0, 100 );
        //this.camera.position.set( 0, 0, 1000 );

        
        //this.controls = new OrbitControls( this.camera,this.$refs.canvas )
       
    },
    mounted: function() {
        this.$refs.canvas.appendChild(this.renderer.domElement)
        this.controls = new TrackballControls(this.camera,this.renderer.domElement)
        this.animate()
    },
    methods: {
        animate: function() {
            requestAnimationFrame(this.animate)
            this.renderer.render(this.scene, this.camera)
            this.cube.rotation.y += this.speed
            //this.controls.update()
        }
    },
    computed: {
        rotate: function() {
            if (this.speed === '') {
                return 0
            } else {
                return this.speed
            }
        }
    }
}
</script>

